<div class="container content">

  <div class="row mt-5">
    <div class="col-lg-6 offset-lg-3">
      <span class="tag">MfG - mit freundlichen Grüssen.</span>
      <h1 class="page-title text-uppercase mb-5">Contact</h1>

      <div class="alert alert-success" role="alert" *ngIf="success">
        {{success}}
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="error">
        {{error}}
      </div>

      <form [formGroup]="form" (ngSubmit)="sendContactMessage()">

        <div class="form-group">
          <label for="name">Name*</label>
          <input type="email" class="form-control" id="name" [formControl]="nameField"
                 [class.is-invalid]="showValidationResult(nameField)">
          <div class="invalid-feedback">Das Feld darf nicht leer sein.</div>
        </div>

        <div class="form-group">
          <label for="email">E-Mail*</label>
          <input type="email" class="form-control" id="email" [formControl]="mailField"
                 [class.is-invalid]="showValidationResult(mailField)">
          <div class="invalid-feedback">Bitte gültige E-Mail-Adresse eingeben.</div>
        </div>

        <div class="form-group">
          <label for="message">Nachricht*</label>
          <textarea class="form-control" id="message" rows="5" [formControl]="messageField"
                    [class.is-invalid]="showValidationResult(messageField)"></textarea>
          <div class="invalid-feedback">Das Feld darf nicht leer sein.</div>
        </div>

        <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Senden</button>
      </form>
    </div>
  </div>

</div>
