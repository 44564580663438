import {Component, NgZone, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {Gallery} from './picture';
import SwiperCore, {Navigation, Pagination, Swiper} from 'swiper';
import {isChromeForIOs145} from '../util/util';
import {GalleryService} from "./gallery.service";
import {Observable} from "rxjs";

SwiperCore.use([Pagination, Navigation]);

@Component({
  templateUrl: 'gallery-zoomed-desktop.component.html',
  styleUrls: ['gallery-zoomed-desktop.component.scss'],
  selector: 'app-gallery-zoomed',
  encapsulation: ViewEncapsulation.None
})
export class GalleryZoomedDesktopComponent {

  public gallery: Observable<Gallery>;
  public pictureIndex: number = 0;
  public isChromeForIOs145 = isChromeForIOs145();

  private readonly gallerySlug: string;

  constructor(route: ActivatedRoute,
              private readonly location: Location,
              private readonly ngZone: NgZone,
              private readonly galleryService: GalleryService) {
    this.gallerySlug = route.snapshot.params.extId;
    this.gallery = this.galleryService.getGalleryBySlug(this.gallerySlug)
    this.pictureIndex = parseInt(route.snapshot.params.nr);
  }

  protected updatePicture(pictureIndex: number): void {
    this.location.go(`/photo/galleries/${this.gallerySlug}/pictures/${pictureIndex}`);
    this.pictureIndex = pictureIndex;
  }

  public onSlideChange(swiper): void {
    this.ngZone.run(() => {
      this.updatePicture((swiper as Swiper).realIndex);
    });
  }

}
