import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Gallery} from './picture';

@Component({
  templateUrl: 'gallery.component.html',
  styleUrls: ['gallery.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gallery'
})
export class GalleryComponent {

  public activeImage = -1;

  @Input()
  public gallery: Gallery;

  constructor(private readonly router: Router) {
  }

  public openImage(picture: number): void {
    this.router.navigate([`/photo/galleries/${this.gallery.slug}/pictures/${picture}`]);
  }

  public hasColSpacing(index: number): boolean {
    const pictureCount = this.gallery.pictures.length;
    const lastRowItemCount = pictureCount % 3 !== 0 ? pictureCount % 3 : 3;
    return index < (pictureCount - lastRowItemCount);
  }

}
