import {Component, ElementRef, ViewChild} from '@angular/core';
import {isTouchDevice} from './util/util';
import {GetColorName} from "hex-color-to-color-name";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('canvas')
  public canvas: ElementRef<HTMLCanvasElement>;

  public color: string;
  public darkMode = false;
  public effectOn = false;

  constructor() {
    if (!isTouchDevice) {
      this.handleMouseMove();
    }
  }
  private handleMouseMove(): void {
    const root = document.documentElement;
    root.addEventListener('mousemove', e => {
      root.style.setProperty('--mouse-x', e.clientX + 'px');
      root.style.setProperty('--mouse-y', e.clientY + 'px');
      const green = Math.floor(e.clientX / window.innerWidth * 255);
      const blue = Math.floor(e.clientY / window.innerHeight * 255);
      const color = 'rgb(235, ' + green + ', ' + blue + ')';
      root.style.setProperty('--highlight-color', color);
      this.color = GetColorName(this.rgbToHex(240, green, blue));
    });
  }

  private componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  private rgbToHex(r: number, g: number, b: number): string {
    return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  public switchColorMode(): void {
    this.darkMode ? this.setLightMode() : this.setDarkMode();
  }

  private setDarkMode(): void {
    this.effectOn = true;
    setTimeout(() => {
      this.effectOn = false;
      this.darkMode = true;
      this.switchColors();
    }, 500);
  }

  private setLightMode(): void {
    this.effectOn = true;
    setTimeout(() => {
      this.effectOn = false;
      this.darkMode = false;
      this.switchColors();
    }, 500);
  }

  private switchColors(): void {
    const root = document.documentElement;
    const colorBackground = '--color-background';
    const colorForeground = '--color-foreground';
    const darkColor = '0, 0, 0';
    const lightColor = '255, 255, 255';
    root.style.setProperty(colorBackground, this.darkMode ? darkColor : lightColor);
    root.style.setProperty(colorForeground, this.darkMode ? lightColor : darkColor);
  }

}
