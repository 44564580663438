<div class="container content mt-6">

  <span class="tag">Züioberland 2022</span>
  <h1 class="page-title text-uppercase mb-5">Kalender</h1>

  <div class="alert alert-success mb-5" role="alert" *ngIf="success">
    {{success}}
  </div>

  <div class="row mb-5">
    <div class="col-lg-6">
      <div class="card mb-3">
        <div class="card-body">
          <ik-image path="/assets/calendar/2022/Kalender_ZO_Shop_Cover.jpg"
                    [transformation]="[{'width': 1080}]"
                    class="w-100"></ik-image>
        </div>
      </div>
    </div>
    <div class="col-lg-6">

      <h2 class="font-weight-lighter mb-5">Factsheet</h2>

      <table class="mb-5">
        <tr>
          <td class="font-weight-bolder text-uppercase pr-3">GRÖSSE</td>
          <td class="font-weight-lighter">A3 quer</td>
        </tr>
        <tr>
          <td class="font-weight-bolder text-uppercase pr-3">PAPIER</td>
          <td class="font-weight-lighter">Fotopapier matt 234g/m2</td>
        </tr>
        <tr>
          <td class="font-weight-bolder text-uppercase pr-3">PARTNER</td>
          <td class="font-weight-lighter">Standortförderung Zürioberland & Papeterie Kostbarkeiten</td>
        </tr>
<!--        <tr>-->
<!--          <td class="font-weight-bolder text-uppercase pr-3">Preis</td>-->
<!--          <td class="font-weight-lighter">39 CHF</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td class="font-weight-bolder text-uppercase pr-3">Versandkosten</td>-->
<!--          <td class="font-weight-lighter">10 CHF</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td class="font-weight-bolder text-uppercase pr-3">Bezahlung</td>-->
<!--          <td class="font-weight-lighter">Vorauskasse: Twint oder Banküberweisung</td>-->
<!--        </tr>-->
      </table>

<!--      <div class="d-flex mb-3">-->
<!--        <div class="form-group mb-0 mr-3">-->
<!--          <input type="number" min="1" max="20" class="form-control" id="count" [formControl]="countField"-->
<!--                 [class.is-invalid]="showValidationResult(mailField)">-->
<!--        </div>-->
<!--        <button class="btn text-white btn-highlight mr-3" (click)="openModal(formModal)">-->
<!--          <i class="bi-cart-fill mr-2"></i>-->
<!--          Bestellen-->
<!--        </button>-->
<!--        <div class="d-flex align-items-center">-->
<!--          <span class="font-weight-bolder text-uppercase mr-2">Total</span> <span-->
<!--          class="font-weight-lighter">{{getTotalPrice()}} CHF (inkl. Versand)</span>-->
<!--        </div>-->

<!--      </div>-->

    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="card mb-3">
        <div class="card-body">
          <ik-image path="/assets/calendar/2022/Kalender_ZO_Shop_1.jpg"
                    [transformation]="[{'width': 1080}]"
                    class="w-100"></ik-image>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card mb-3">
        <div class="card-body">
          <ik-image path="/assets/calendar/2022/Kalender_ZO_Shop_2.jpg"
                    [transformation]="[{'width': 1080}]"
                    class="w-100"></ik-image>
        </div>
      </div>
    </div>
  </div>

</div>


<!--<ng-template #formModal let-modal>-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title" id="modal-basic-title">Zürioberland-Kalender 2022 bestellen</h4>-->
<!--    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->

<!--    <div class="alert alert-danger" role="alert" *ngIf="error">-->
<!--      {{error}}-->
<!--    </div>-->

<!--    <form [formGroup]="form" (ngSubmit)="order()">-->

<!--      <div class="form-group">-->
<!--        <label for="name">Vorname & Name*</label>-->
<!--        <input type="text" class="form-control" id="name" [formControl]="nameField"-->
<!--               [class.is-invalid]="showValidationResult(nameField)">-->
<!--        <div class="invalid-feedback">Das Feld darf nicht leer sein.</div>-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <label for="address">Adresse*</label>-->
<!--        <input type="text" class="form-control" id="address" [formControl]="addressField"-->
<!--               [class.is-invalid]="showValidationResult(addressField)">-->
<!--        <div class="invalid-feedback">Das Feld darf nicht leer sein.</div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-lg-6">-->
<!--          <div class="form-group">-->
<!--            <label for="plz">Postleitzahl*</label>-->
<!--            <input type="text" class="form-control" id="plz" [formControl]="plzField"-->
<!--                   [class.is-invalid]="showValidationResult(plzField)">-->
<!--            <div class="invalid-feedback">Das Feld darf nicht leer sein.</div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-lg-6">-->
<!--          <div class="form-group">-->
<!--            <label for="city">Ort*</label>-->
<!--            <input type="text" class="form-control" id="city" [formControl]="cityField"-->
<!--                   [class.is-invalid]="showValidationResult(cityField)">-->
<!--            <div class="invalid-feedback">Das Feld darf nicht leer sein./div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <label for="email">E-Mail*</label>-->
<!--        <input type="email" class="form-control" id="email" [formControl]="mailField"-->
<!--               [class.is-invalid]="showValidationResult(mailField)">-->
<!--        <div class="invalid-feedback">Bitte gültige E-Mail-Adresse eingeben.</div>-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <label for="phone">Telefonnummer</label>-->
<!--        <input type="text" class="form-control" id="phone" [formControl]="phoneField">-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <label for="message">Nachricht</label>-->
<!--        <textarea class="form-control" id="message" rows="5" [formControl]="messageField"></textarea>-->
<!--      </div>-->

<!--      <p class="font-weight-lighter">Nach Bestelleingang erhalten Sie eine E-Mail mit den Zahlungsinformationen (Twint-->
<!--        bzw. Banküberweisung).</p>-->


<!--      <div class="d-flex mb-3 justify-content-end">-->
<!--        <div class="d-flex align-items-center">-->
<!--          <span class="font-weight-bolder text-uppercase mr-2">Total</span> <span-->
<!--          class="font-weight-lighter">{{getTotalPrice()}} CHF (inkl. Versand)</span>-->
<!--        </div>-->
<!--        <div class="form-group mb-0 ml-3 mr-3">-->
<!--          <input type="number" min="1" max="20" class="form-control" [formControl]="countField"-->
<!--                 [class.is-invalid]="showValidationResult(mailField)">-->
<!--        </div>-->
<!--        <button type="submit" class="btn text-white btn-highlight" [disabled]="form.invalid">-->
<!--          <i class="bi-cart-fill mr-2"></i>-->
<!--          Bestellen-->
<!--        </button>-->
<!--      </div>-->


<!--    </form>-->

<!--  </div>-->
<!--</ng-template>-->
