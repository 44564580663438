import {Component} from '@angular/core';
import {News} from './news';
import {NewsService} from "./news.service";
import {Observable} from "rxjs";

@Component({
  templateUrl: 'news.component.html',
  styleUrls: ['news.component.scss']
})
export class NewsComponent {

  public news: Observable<News[]>;

  constructor(private readonly newsService: NewsService) {
    this.news = this.newsService.getNews();
  }


}
