export class GoogleTagManager {

  private static readonly browserGlobals = {
    windowRef(): any {
      return window;
    },
  };

  public static pushTag(item: { event: string, [property: string]: any }): void {
    // The dataLayer is flushed only if the page is reloaded.
    // To avoid collecting old variable data in case of multiple push actions
    // without reloading the page, the data layer is reset
    // before each push assuming that each push is atomic.
    this.resetDataLayer();
    this.pushOnDataLayer(item);
  }

  private static pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private static resetDataLayer(): void {
    const dataLayer = this.getDataLayer();
    // eslint-disable-next-line
    dataLayer.push(function() {
      // @ts-ignore the compiler misinterprets this scope
      this.reset();
    });
  }

  private static getDataLayer(): object[] {
    const window = this.browserGlobals.windowRef();
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }

}
