<div class="d-none d-sm-block">
  <h3 class="text-uppercase mb-3"><span
    class="font-weight-bolder"> {{gallery.name}}</span>  {{gallery.year}}</h3>
  <div class="card mb-6" *ngIf="activeImage === -1;">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4" *ngFor="let picture of gallery.pictures; index as i"
             [class.col-spacing]="hasColSpacing(i)">
          <img src="/directus/assets/{{picture.directus_files_id.filename_disk}}?width=600" (click)="openImage(i)" class="w-100 pointer">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-block d-sm-none">
  <app-gallery-zoomed-mobile [gallerySlug]="gallery.slug"></app-gallery-zoomed-mobile>
</div>
