import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GoogleTagManager} from '../util/google-tag-manager';

@Component({
  templateUrl: 'calendar.component.html'
})
export class CalendarComponent {

  public form: UntypedFormGroup;
  public countField: UntypedFormControl = new UntypedFormControl(1, Validators.required);
  public nameField: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public addressField: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public plzField: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public cityField: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public mailField: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  public phoneField: UntypedFormControl = new UntypedFormControl('');
  public messageField: UntypedFormControl = new UntypedFormControl('');

  public success: string;
  public error: string;

  constructor(private modalService: NgbModal) {
    this.form = new UntypedFormGroup({
      name: this.nameField,
      mail: this.mailField,
      message: this.messageField
    });

    GoogleTagManager.pushTag({
      event: 'calendarPageOpened',
      title: 'Calendar Page opened'
    });
  }

  public openModal(content: any): void {
    this.modalService.open(content, {size: 'xl', centered: true});
  }

  // public order(): void {
  //   this.success = '';
  //   this.error = '';
  //
  //   GoogleTagManager.pushTag({
  //     event: 'calendarPurchased',
  //     title: 'Calendar Purchased',
  //     count: this.countField.value
  //   });
  //
  //   const order: OrderDto = {
  //     count: this.countField.value,
  //     name: this.nameField.value,
  //     address: this.addressField.value,
  //     plz: this.plzField.value,
  //     city: this.cityField.value,
  //     email: this.mailField.value,
  //     phone: this.phoneField.value,
  //     message: this.messageField.value,
  //   };
  //   this.httpClient.order(order).subscribe(
  //     success => {
  //       this.success = 'Deine Bestellung wurde erfolgreich übermittelt. Herzlichen Dank.';
  //       this.modalService.dismissAll();
  //       this.form.reset();
  //     },
  //     error => this.error = 'Ein Fehler ist aufgetreten.'
  //   );
  // }

  public showValidationResult(formControl: UntypedFormControl): boolean | null {
    return formControl && formControl.invalid && (formControl.touched || formControl.dirty);
  }

  public getTotalPrice(): number {
    return this.countField.value * 49;
  }

}
