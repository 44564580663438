import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {LivingRoomGalleryComponent} from './living-room-gallery/living-room-gallery.component';
import {RouterModule, Routes} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GalleryComponent} from './photography/gallery.component';
import {SwiperModule} from 'swiper/angular';
import {NavbarComponent} from './common/navbar.component';
import {HomeComponent} from './home/home.component';
import {FooterComponent} from './common/footer.component';
import {GalleryZoomedDesktopComponent} from './photography/gallery-zoomed-desktop.component';
import {GalleryZoomedMobileComponent} from './photography/gallery-zoomed-mobile.component';
import {PhotographyComponent} from './photography/photography.component';
import {ContactComponent} from './contact/contact.component';
import {WebdesignComponent} from './webdesign/webdesign.component';
import {NewsComponent} from './news/news.component';
import {ImagekitioAngularModule} from 'imagekitio-angular';
import {environment} from '../environments/environment.prod';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AboutComponent} from './about/about.component';
import {CalendarComponent} from './calendar/calendar.component';

const ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'news',
    component: NewsComponent,
  },
  {
    path: 'calendar',
    component: CalendarComponent,
  },
  {
    path: 'photo',
    children: [
      {
        path: '',
        component: PhotographyComponent,
      },
      {
        path: 'galleries/:extId/pictures/:nr',
        component: GalleryZoomedDesktopComponent
      }
    ]
  },
  {
    path: 'webdesign',
    component: WebdesignComponent,
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'living-room',
    component: LivingRoomGalleryComponent
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LivingRoomGalleryComponent,
    HomeComponent,
    NewsComponent,
    CalendarComponent,
    PhotographyComponent,
    GalleryComponent,
    GalleryZoomedDesktopComponent,
    GalleryZoomedMobileComponent,
    WebdesignComponent,
    AboutComponent,
    ContactComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES, {relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'}),
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    SwiperModule,
    ImagekitioAngularModule.forRoot({
      publicKey: environment.publicKey,
      urlEndpoint: environment.urlEndpoint,
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
