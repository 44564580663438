<div class="content h-100 d-flex flex-column">
  <div class="container flex-grow-1">
    <div class="h-100 d-flex flex-column justify-content-around">
      <h1 class="quote">
        I can’t get a<br>
        <span class="highlight">life</span> if my <span class="highlight">heart’s</span><br>
        not in it.<a href="https://www.musixmatch.com/de/songtext/Oasis/The-Importance-Of-Being-Idle" target="_blank">
          <span class="tag">Oasis</span>
        </a>
      </h1>
    </div>
  </div>
</div>

<div class="h-100 d-flex flex-column home-background-picture">
  <div class="container flex-grow-1">
    <div class="h-100 d-flex flex-column justify-content-around link">
      <h1 class="quote text-center">
        <a routerLink="/photo"><span class="tag">More of that.</span></a>
      </h1>
    </div>
  </div>
</div>
