<div class="circle uk-position-fixed uk-position-z-index uk-background-default uk-flex uk-flex-middle">
  <!--  <h3 class="uk-text-bold uk-margin-remove-top uk-margin-remove-bottom ">Mirko Reichlin</h3>-->
  <!--  <img src="/assets/hamburger-black.svg" class="uk-margin-right uk-margin-left">-->
  <img src="/assets/logo-black.svg" class="logo uk-position-z-index  uk-position-fixed ">
  <div class="logo-text">A visual experience by <br>Mirko Reichlin</div>
</div>

<!--TODO Frontpage-->
<!--<div class="uk-padding uk-height-viewport uk-flex">-->
<!--  <div id="home" class="uk-position-relative uk-background-cover uk-overflow-hidden uk-flex-1 uk-flex">-->
<!--    <div class="uk-position-relative uk-light uk-flex-1 uk-flex" tabindex="-1"-->
<!--         uk-slideshow="ratio: false; animation: pull; autoplay: true; autoplay-interval: 3000; finite: false">-->

<!--      <ul class="uk-slideshow-items uk-flex-1 uk-flex">-->
<!--        <li class="uk-background-cover uk-flex-1" data-src="/assets/images/1.jpg" uk-img>-->
<!--        </li>-->
<!--        <li class="uk-background-cover uk-flex-1" data-src="/assets/images/4.jpg" uk-img>-->
<!--        </li>-->
<!--        <li class="uk-background-cover uk-flex-1" data-src="/assets/images/8.jpg" uk-img>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div class="uk-blend-difference uk-overflow-hidden spinner-wrapper">-->
<!--      <img src="/assets/logo-white.svg" class="spinner-logo"-->
<!--           uk-parallax="opacity: 1,1; x: 0,0; y: 0,0; scale: 1,3; rotate:0,540; viewport: 1;">-->
<!--    </div>-->
<!--    <div class="code-and-photo uk-position-center uk-blend-difference uk-light">-->
<!--      <h1 class="uk-heading-xlarge uk-text-bold">-->
<!--        <a href="#code" uk-scroll>code</a> & <a href="#photo" uk-scroll>photo</a></h1>-->
<!--    </div>-->

<!--  </div>-->
<!--</div>-->

<!-- Gallery -->
<div id="photo" class="uk-padding uk-height-viewport uk-grid">

  <!--  Vertical Navigation-->
  <div class="uk-width-1-4@l uk-visible@l height-inner">
    <div class="uk-position-relative uk-flex-1" uk-filter="target: .gallery-thumbs">

      <ul class="gallery-filter uk-subnav uk-subnav-pill uk-dark uk-margin-remove-bottom">
        <li uk-filter-control><a href="#">Alle</a></li>
        <ng-container *ngFor="let category of getCategoriesFromPictures(pictures)">
          <li [attr.uk-filter-control]="'.'+category"><a href="#">{{category}}</a></li>
        </ng-container>
      </ul>


      <div class="scroller scroller-top uk-card-default uk-position-z-index" (mouseover)="startScroller(5, -1)"
           (mouseout)="stopScroller()"><img src="/assets/arrow-up.svg"></div>

      <div class="scroller scroller-bottom uk-card-default uk-position-z-index" (mouseover)="startScroller(5)"
           (mouseout)="stopScroller()"><img src="/assets/arrow-down.svg"></div>

      <!--      <div class="uk-position-relative">-->
      <!--        <div class="scroller-wrapper uk-position-absolute uk-position-z-index">-->
      <!--          <div class="scroller scroller-top uk-card-default" (mouseover)="startScroller(5, -1)"-->
      <!--               (mouseout)="stopScroller()"><span uk-icon="icon: arrow-up; ratio: 2"></span></div>-->

      <!--          <div class="scroller scroller-bottom uk-card-default" (mouseover)="startScroller(5)"-->
      <!--               (mouseout)="stopScroller()"><span uk-icon="icon: arrow-down; ratio: 2"></span></div>-->
      <!--        </div>-->
      <!--      </div>-->

      <ul class="gallery-thumbs uk-light uk-flex-1" #galleryThumbs>
        <li *ngFor="let picture of pictures">
          <div class="w-100 uk-position-relative" (click)="setActiveImgUrl(picture.filename_download)">
            <img [src]="picture.filename_download" [alt]="picture.title" class="w-100">
            <div class="uk-position-center uk-panel"><h3>{{picture.title}}</h3></div>
          </div>
        </li>
      </ul>
    </div>

  </div>

  <div class="uk-width-3-4@l" bg-sofa uk-position-relative>
    <div class="bg-sofa uk-position-relative" [class.bg-sofa-zoomed]="isInLivingRoom">

      <div class="picture-frame" [class.picture-frame-zoomed]="isInLivingRoom">
        <img [src]="activeImgUrl" class="picture">
      </div>
      <div class="uk-button-group uk-margin-bottom uk-position-bottom-center">
        <button class="uk-button uk-button-default uk-background-default" (click)="toggleAfter()">
          {{ isInLivingRoom ? 'Wohnzimmer ausblenden' : 'Wohnzimmer einblenden' }}
        </button>
        <button class="uk-button uk-button-default uk-background-default">
          Preis auf Anfrage
        </button>
      </div>
    </div>
  </div>

  <!--  Horizontal Navigation-->
  <div class="uk-width-1-5@l uk-hidden@l">

    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="ratio: 3:1; animation: push">
      <ul class="uk-slideshow-items">
        <li  *ngFor="let picture of pictures">
          <div (click)="setActiveImgUrl(picture.directus_files_id.filename_disk)">
            <img [src]="picture.directus_files_id.filename_disk" [alt]="picture.directus_files_id.title" uk-cover>
            <div class="uk-position-cover uk-panel"><h3>{{picture.directus_files_id.title}}</h3></div>
          </div>
        </li>
      </ul>

      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
         uk-slider-item="previous"></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
         uk-slider-item="next"></a>

    </div>
  </div>
</div>
