import {Component, Input, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
import {Gallery, Picture} from './picture';
import SwiperCore, {Navigation, Pagination, Swiper} from 'swiper';
import {isChromeForIOs145} from '../util/util';
import {Observable} from "rxjs";
import {GalleryService} from "./gallery.service";

SwiperCore.use([Pagination, Navigation]);

@Component({
  templateUrl: 'gallery-zoomed-mobile.component.html',
  styleUrls: ['gallery-zoomed-mobile.component.scss'],
  selector: 'app-gallery-zoomed-mobile',
  encapsulation: ViewEncapsulation.None
})
export class GalleryZoomedMobileComponent implements OnInit{

  public isChromeForIOs145 = isChromeForIOs145();

  public pictureIndex = 0;

  @Input()
  public gallerySlug: string;
  public gallery: Observable<Gallery>;

  constructor(private readonly ngZone: NgZone,
              private readonly galleryService: GalleryService) {
  }

  ngOnInit() {
    this.gallery = this.galleryService.getGalleryBySlug(this.gallerySlug);
  }

  public onSlideChange(swiper): void {
    this.ngZone.run(() => {
      this.pictureIndex = (swiper as Swiper).realIndex
    });
  }

}
