import {Injectable} from "@angular/core";
import {Gallery} from "../photography/picture";
import {Directus} from "@directus/sdk";
import {environment} from "../../environments/environment.prod";
import {Contact} from "../contact/contact";
import {News} from "../news/news";
import {Webdesign} from "../webdesign/webdesigns";

const apiToken = environment.DIRECTUS_TOKEN
const directusURL = environment.DIRECTUS_URL


type Collections = {
  news: News,
  galleries: Gallery;
  webdesigns: Webdesign;
  contact_form_messages: Contact
}

@Injectable({providedIn: 'root'})
export class ApiService {

  private directusApi = new Directus<Collections>(directusURL, {
    auth: {staticToken: apiToken}
  });

  public get api(): Directus<Collections> {
    return this.directusApi;
  }


}
