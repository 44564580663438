<div class="content container" *ngIf="webdesigns | async as webdesigns">

  <div class="d-lg-flex mt-6 mb-6">
    <div>
      <h1 class="page-title text-uppercase  d-inline-block">
        Webdesign
      </h1>
      <p class="font-weight-bolder text-lg-right">
        <a href="#" routerLink="/contact"><span class="highlight">Can I create a website for you?</span></a>
      </p>
    </div>
    <p class="page-title-description ml-lg-3 font-weight-lighter">
      Massgeschneiderte Kleider können wunderschön sein, doch im Alltag leisten wir uns sie nur selten. So oder so
      ähnlich ist
      das auch bei Webseiten. Gerne programmiere ich massgeschneiderte Webseiten und Webshops für dein Unternehmen.
      Bei kleineren Budgets biete ich auch "Ware ab der WordPress-Stange" an – da arbeite ich mit anpassbaren Vorlagen
      für deinen digitalen Auftritt.<br><br>

      Gerne gebe ich mehr Auskunft dazu. <a href="#" routerLink="/contact" class="font-weight-bolder">Kontaktiere</a>
      mich für ein erstes, unverbindliches Gespräch.
    </p>
  </div>


  <div class="row mb-6" *ngFor="let webdesign of webdesigns; odd as odd">
    <div class="col-lg-4 mb-3 mb-lg-0 order-lg-2">
      <div class="card">
        <div class="card-body">
          <h1><a [href]="webdesign.domain" target="_blank">{{webdesign.domain_short}}</a></h1>
          <p>{{webdesign.description}}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-8  mb-3 mb-lg-0 order-lg-1">
      <a [href]="webdesign.domain" target="_blank">
        <div class="card">
          <div class="card-body">
            <img src="/directus/assets/{{webdesign.image.filename_disk}}.png?width=1200"
                      class="w-100">
          </div>
        </div>
      </a>
    </div>
  </div>

</div>
