<nav class="navbar navbar-expand-lg"
     [class.navbar-dark]="(darkMode && !navbarOpen) || (!darkMode && navbarOpen)"
     [class.navbar-light]="(!darkMode && !navbarOpen) || (darkMode && navbarOpen)"
     [class.navbar-open]="navbarOpen">
  <a class="navbar-brand font-weight-bolder text-uppercase" href="">Mirko Reichlin</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          (click)="toggleNavbar()">
    <i class="bi-three-dots"></i>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" [class.show]="navbarOpen"
       (click)="closeNavbar()">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="#" [routerLink]="['/news']" [routerLinkActive]="['active']">News</a>
      </li>
      <!--
      <li class="nav-item">
        <a class="nav-link" href="#" [routerLink]="['/calendar']" [routerLinkActive]="['active']">Calendar 2022</a>
      </li>
      -->
      <li class="nav-item">
        <a class="nav-link" href="#" [routerLink]="['/photo']" [routerLinkActive]="['active']">Photography</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" [routerLink]="['/webdesign']" [routerLinkActive]="['active']">Webdesign</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="#" [routerLink]="['/about']" [routerLinkActive]="['active']">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" [routerLink]="['/contact']" [routerLinkActive]="['active']">Contact</a>
      </li>
    </ul>
  </div>
</nav>
