<div class="container content">
  <div class="row mt-5">
    <div class="col-lg-6 offset-lg-3">

      <span class="tag">More</span>
      <h1 class="page-title text-uppercase mb-5">About me</h1>

      <p class="font-weight-lighter">
        Hi. Auf folgenden Seiten findest du mehr über mich

      </p>

      <!--      <p class="h3 font-weight-lighter mb-4">Find me on</p>-->

      <div class="card mb-4">
        <div class="card-body">
          <h1>
            <a href="https://www.instagram.com/mirkoreichlin/" target="_blank">
              <i class="bi-instagram"></i>
              <span class="ml-3 font-weight-bolder">Instagram</span>
            </a>
          </h1>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-body">
          <h1>
            <a href="https://www.linkedin.com/in/mirkoreichlin/" target="_blank">
              <i class="bi-linkedin"></i>
              <span class="ml-3 font-weight-bolder">LinkedIn</span>
            </a>
          </h1>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <ik-image path="/assets/portrait.jpg"
                    [transformation]="[{'width': 1080}]"
                    class="w-100"></ik-image>
        </div>
      </div>


    </div>
  </div>
</div>
