import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Contact} from './contact';
import {ApiService} from "../common/api.service";
import {from} from "rxjs";

@Component({
  templateUrl: 'contact.component.html',
})
export class ContactComponent {

  public form: UntypedFormGroup;
  public nameField: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public mailField: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  public messageField: UntypedFormControl = new UntypedFormControl('', Validators.required);

  public success: string;
  public error: string;

  constructor(private readonly apiService: ApiService) {
    this.form = new UntypedFormGroup({
      name: this.nameField,
      mail: this.mailField,
      message: this.messageField
    });
  }

  public sendContactMessage(): void {
    this.success = '';
    this.error = '';
    const contactMessage: Contact = {
      name: this.nameField.value,
      from_email: this.mailField.value,
      message: this.messageField.value
    };
    // this.httpClient.sendContactMessage(contactMessage)
    from(this.apiService.api.items('contact_form_messages').createOne(contactMessage)).subscribe(
      success => {
        this.success = 'Deine Nachricht wurde erfolgreich übermittelt.';
        this.form.reset();
      },
      error => this.error = 'Ein Fehler ist aufgetreten.'
    );
  }

  public showValidationResult(formControl: UntypedFormControl): boolean | null {
    return formControl && formControl.invalid && (formControl.touched || formControl.dirty);
  }

}
