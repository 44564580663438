export interface Gallery {
  slug: string;
  name: string;
  year: number;
  month: string;
  vertical: boolean;
  pictures: Picture[];
  category: Category
}

export interface Picture {
  directus_files_id: {
    title: string;
    filename_disk: string;
  }
}

export enum Category {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
  REPORTAGE = 'reportage'
}

export enum PictureCategory {
  WINTER = 'Winter',
}
