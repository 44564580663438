import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})
export class NavbarComponent {

  public navbarOpen = false;

  @Input()
  public darkMode: boolean;

  public toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }

  public closeNavbar(): void {
    this.navbarOpen = false;
  }


}
