<app-navbar [darkMode]="darkMode"></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>


<div class="theme-switcher-effect" [class.grow]="effectOn"></div>
<a (click)="switchColorMode()" class="theme-switcher"></a>

<div class="line-container">
  <div class="vertical-line"></div>
  <div class="horizontal-line"></div>
  <div class="vertical-color-name">{{color}}</div>
  <div class="horizontal-color-name">{{color}}</div>
</div>

