import {Component, ElementRef, ViewChild} from '@angular/core';
import {Picture, PictureCategory} from '../photography/picture';

@Component({
  templateUrl: 'living-room-gallery.component.html',
  styleUrls: ['living-room-gallery.component.scss'],
  selector: 'app-living-room-gallery',
})
export class LivingRoomGalleryComponent {
  @ViewChild('galleryThumbs')
  private galleryThumbs: ElementRef;

  public pictures: Picture[] = [];
  public activeImgUrl = this.pictures[0].directus_files_id.filename_disk;
  public isInLivingRoom = false;
  public scroller;

  public scrollTo(element: HTMLElement): void {
    element.hidden = false;
    element.scrollIntoView({behavior: 'smooth'});
  }

  public setActiveImgUrl(url: string): void {
    this.activeImgUrl = url;
  }

  public toggleAfter(): void {
    this.isInLivingRoom = !this.isInLivingRoom;
  }

  public getCategoriesFromPictures(pictures: Picture[]): PictureCategory[] {
    const categories = [PictureCategory.WINTER];//pictures.reduce((cat: any[], picture) => cat.concat(picture.categories), []);
    return [...new Set(categories)];
  }

  public getCssClasses(categories: PictureCategory[]): string {
    return categories.reduce((category, classString) => `${classString} ${category}`, '');
  }

  public startScroller(speed: number, direction = 1): void {
    this.scroller = setInterval(() => this.galleryThumbs.nativeElement.scrollTop += direction * speed, 3);
  }

  public stopScroller(): void {
    clearInterval(this.scroller);
  }
}
