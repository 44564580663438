import {Component} from '@angular/core';
import {Webdesign} from './webdesigns';
import {ApiService} from "../common/api.service";
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";

const WEBDESIGNS_FIELDS: string[] = ['year', 'description', 'domain', 'domain_short', 'image.*.filename_disk'];


@Component({
  templateUrl: 'webdesign.component.html'
})
export class WebdesignComponent {

  public webdesigns: Observable<Webdesign[]>;

  constructor(private readonly apiService: ApiService) {
    this.webdesigns = this.getWebdesigns();
  }

  private getWebdesigns(): Observable<Webdesign[]> {
    // @ts-ignore
    return from(this.apiService.api.items('webdesigns').readByQuery({fields: WEBDESIGNS_FIELDS, sort: 'sort'})).pipe(
      map(items => items.data)
    );
  }

}
