import {Component} from '@angular/core';
import {Category, Gallery} from './picture';
import {Observable} from "rxjs";
import {GalleryService} from "./gallery.service";


@Component({
  templateUrl: 'photography.component.html',
  styleUrls: ['photography.component.scss']
})
export class PhotographyComponent {

  public galleries: Observable<Gallery[]>
  public categories = Category;

  constructor(private readonly galleryService: GalleryService) {
    this.galleries = galleryService.getGalleries();
  }

  public filterByCategory(galleries: Gallery[], category: Category): Gallery[] {
    return galleries.filter(gallery => gallery.category === category);
  }

  public scrollToElement(element: HTMLElement): void {
    element.scrollIntoView({behavior: 'smooth'});
  }

}
