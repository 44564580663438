<div class="container content" *ngIf="galleries | async as galleries">
  <div class="text-center font-weight-bolder mt-5 mb-5">
    <a (click)="scrollToElement(landscape)" class="mr-5 pointer"><span class="tag">Landscape</span> </a>
    <a (click)="scrollToElement(portrait)" class="mr-5 pointer"><span class="tag">Portrait</span> </a>
    <a (click)="scrollToElement(reportage)" class="pointer"><span class="tag">Reportage</span> </a>
  </div>

  <div class="d-lg-flex mt-6 mb-6 scroll-margin" #landscape>
    <div>
      <p class="mb-0 text-uppercase font-weight-bolder">Photography</p>
      <h1 class="page-title text-uppercase  d-inline-block">
        Landscape
      </h1>
      <p class="font-weight-bolder text-lg-right">
        <a href="#" routerLink="/contact"><span class="highlight">Can I take pictures for you?</span></a>
      </p>
    </div>
    <p class="page-title-description ml-lg-3  font-weight-lighter">
      Mitten in der Nacht aufstehen. Die gepackte Ausrüstung ins Auto verladen und losfahren. In der Morgendämmerung in
      die
      leuchtenden Augen der Füchse und Rehe schauen. Die letzten Kilometer zu Fuss zurücklegen. Das Stativ vor
      Sonnenaufgang positionieren. Auf die perfekte Morgenstimmung warten und auf den Auslöser drücken. Dies ist für
      mich
      Landschaftsfotografie. Gerne bin ich auch für deine Tourismus-Region als Fotograf unterwegs.
      <a href="#" routerLink="/contact" class="font-weight-bolder">Jetzt anfragen.</a>
      <br><br>
      Auf <a href="#" routerLink="/contact" class="font-weight-bolder">Anfrage</a> erstelle ich auch Prints.
    </p>
  </div>
  <gallery *ngFor="let gallery of filterByCategory(galleries, categories.LANDSCAPE)" [gallery]="gallery"></gallery>
  <hr class="border-primary mt-6">



  <div class="d-lg-flex mt-6 mb-6 scroll-margin" #portrait>
    <div>
      <p class="mb-0 text-uppercase font-weight-bolder">Photography</p>
      <h1 class="page-title text-uppercase  d-inline-block">
        Portrait
      </h1>
      <p class="font-weight-bolder text-lg-right">
        <a href="#" routerLink="/contact"><span class="highlight">Can I take pictures of you?</span></a>
      </p>
    </div>
    <p class="page-title-description ml-lg-3  font-weight-lighter">
      Natürliches Licht. Echte Menschen. Nahbar und schön. In diesem Stil biete ich sowohl Einzelportraits - Business
      oder Privat - als auch Hochzeitsfotos an.
      <a href="#" routerLink="/contact" class="font-weight-bolder">Jetzt anfragen.</a>
    </p>
  </div>
  <gallery *ngFor="let gallery of filterByCategory(galleries, categories.PORTRAIT)" [gallery]="gallery"></gallery>
  <hr class="border-primary mt-6">



  <div class="d-lg-flex mt-6 mb-6 scroll-margin" #reportage>
    <div>
      <p class="mb-0 text-uppercase font-weight-bolder">Photography</p>
      <h1 class="page-title text-uppercase  d-inline-block">
        Reportage
      </h1>
      <p class="font-weight-bolder text-lg-right">
        <a href="#" routerLink="/contact"><span class="highlight">Can I take pictures for you?</span></a>
      </p>
    </div>
    <p class="page-title-description ml-lg-3  font-weight-lighter">
      Die Welt zeigen, wie sie ist. Aber aus meinem ganz eigenen Blickwinkel. Ob Fischzucht oder Badis.
      Ob Traditionshandwerk oder moderne Büros. Gerne bin ich für dich oder deine Firma als Reportagefotograf unterwegs.
      <a href="#" routerLink="/contact" class="font-weight-bolder">Jetzt anfragen.</a>
    </p>
  </div>

  <gallery *ngFor="let gallery of filterByCategory(galleries, categories.REPORTAGE)" [gallery]="gallery"></gallery>
</div>
