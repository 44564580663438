import {Injectable} from "@angular/core";
import {Directus} from "@directus/sdk";
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {News} from "./news";
import {ApiService} from "../common/api.service";

const NEWS_FIELDS: string[] = ['date', 'title', 'description', 'internalLink', 'externalLink', 'image.*.filename_disk'];

@Injectable({providedIn: 'root'})
export class NewsService {

  constructor(private readonly apiService: ApiService) {
  }

  public getNews(): Observable<News[]> {
    // @ts-ignore
    return from(this.apiService.api.items('news').readByQuery({fields: NEWS_FIELDS, sort: '-date'})).pipe(
      map(items => items.data)
    );
  }


}
